import axios from "axios";
import { baseUrl, baseApiVersion, headers } from "../config/env";

export function fetchFileList(params) {
  return axios.get(baseUrl + baseApiVersion + `/file/list`, { params, headers });
}

export function uploadFile(data) {
  headers["Content-Type"] = "multipart/form-data";
  return axios.post(baseUrl + baseApiVersion + `/file/upload`, data, { headers });
}

export function fetchVodSrc(contentsId) {
  return axios.get(baseUrl + baseApiVersion + `/file/main/${contentsId}`, {
    headers,
  });
}

export function fetchWowzaList(params) {
  return axios.get(
    baseUrl +
      baseApiVersion + `/wowza/log?keyword=${params.keyword}&dateRange.from=${params.dateRange.from}&dateRange.to=${params.dateRange.to}`,
    { headers }
  );
}
